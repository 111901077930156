import { ObjectValue, ObjectValueCollection } from '@/utils/objectValue'

// 内容类型枚举

class Ready extends ObjectValue {
  constructor() {
    super(1, '服务准备', 'Ready')
  }
}

class Eat extends ObjectValue {
  constructor() {
    super(2, '吃饭 ', 'Eat')
  }
}
class Rest extends ObjectValue {
  constructor() {
    super(3, '休息', 'Rest')
  }
}
class Leave extends ObjectValue {
    constructor() {
      super(4, '请假', 'Leave')
    }
  }
  class Train extends ObjectValue {
    constructor() {
      super(5, '培训', 'Train')
    }
  }
  class Talk extends ObjectValue {
    constructor() {
      super(6, '谈话', 'Talk')
    }
  }
  class Procrast extends ObjectValue {
    constructor() {
      super(7, '员工拖延', 'Procrast')
    }
  }
  class Late extends ObjectValue {
    constructor() {
      super(8, '客户迟到', 'Late')
    }
  }
  class Serve extends ObjectValue {
    constructor() {
      super(9, 'ta人预约', 'Serve')
    }
  }
  class Meeting extends ObjectValue {
    constructor() {
      super(10, '会议', 'Meeting')
    }
  }


export class OccupyType extends ObjectValueCollection {
    constructor() {
      super()
      this.add(new Ready())
      this.add(new Eat())
      this.add(new Rest())
      this.add(new Leave())
      this.add(new Train())
      this.add(new Talk())
      this.add(new Procrast())
      this.add(new Late())
      this.add(new Serve())
      this.add(new Meeting())
    }
  }
