<template>
  <div class="bottom-tips">
    <div class="item">
      <div class="key state1"></div>
      <div class="value">待到店({{ stayAtTheStore }})</div>
    </div>
    <div class="item">
      <div class="key state2"></div>
      <div class="value">已到店({{ haveArrivedAtTheStore }})</div>
    </div>
    <div class="item">
      <div class="key state4"></div>
      <div class="value">已完成({{ complete }})</div>
    </div>
    <div class="item">
      <div class="key state5"></div>
      <div class="value">占用</div>
    </div>
    <div class="item">
      <div class="key state6"></div>
      <div class="value">空闲</div>
    </div>
    <div class="item">
      <div class="key state7"></div>
      <div class="value">休息</div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watchEffect } from 'vue'

export default defineComponent({
  props: {
    detail: {
      type: Array,
      default() {
        return []
      }
    }
  },

  setup(props) {
    const stayAtTheStore = ref('0')
    const haveArrivedAtTheStore = ref('0')
    const complete = ref('0')
    watchEffect(() => {
      stayAtTheStore.value=props.detail&&props.detail.length&&props.detail.filter(item=>{return item.status==1}).length?props.detail&&props.detail.length&&props.detail.filter(item=>{return item.status==1})[0].count:0
      haveArrivedAtTheStore.value=props.detail&&props.detail.length&&props.detail.filter(item=>{return item.status==2}).length?props.detail&&props.detail.length&&props.detail.filter(item=>{return item.status==2})[0].count:0
      complete.value=props.detail&&props.detail.length&&props.detail.filter(item=>{return item.status==4}).length?props.detail&&props.detail.length&&props.detail.filter(item=>{return item.status==4})[0].count:0
    })
    return {
      stayAtTheStore,
      haveArrivedAtTheStore,
      complete
    }
  }
})
</script>

<style scoped lang="less">
.bottom-tips {
  border-radius: 4px;
  position: fixed;
  right: 58px;
  bottom: 30px;
  min-width: 400px;
  height: 55px;
  color: rgba(80, 80, 80, 1);
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  padding: 0 10px;

  .item {
    display: flex;
    align-items: center;
    margin-right: 10px;

    .key {
      margin-right: 2px;
      width: 20px;
      height: 20px;
    }
  }

  .state1 {
    background-color: rgb(121, 72, 234);
  }

  .state2 {
    background-color: rgb(36, 153, 84);
  }

  .state3 {
    background-color: rgb(42, 130, 228);
  }

  .state4 {
    background-color: rgb(19, 194, 194);
  }

  .state5 {
    background-color: rgb(240, 183, 89);
  }

  .state6 {
    background-color: #fff;
    box-sizing: border-box;
    border: 1px solid #eee;
  }

  // .state6 {
  //   background-color: #fa8c16;
  // }
  .state7 {
    background-color: #e4e3e1;
  }
}
</style>
